import React from 'react';
import { Layout, Stack, Main } from '../layout';
import PageTitle from '../components/PageTitle';
import Divider from '../components/Divider';
import Seo from '../widgets/Seo';
import AuthorExpanded from '../widgets/AuthorExpanded';
import { useBlogAuthors } from '../helpers-blog';

const PageAuthors = props => {
  const authors = useBlogAuthors();

  return (
    <Layout {...props}>
      <Seo title='Our Team' />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle header='Team Members' subheader='' />
      </Stack>
      <Stack>
        <Main>
          {authors.map(author => (
            <>
              <Divider />
              <AuthorExpanded author={author} withLink />
            </>
          ))}
        </Main>
      </Stack>
    </Layout>
  );
};

export default PageAuthors;
